import React, { useEffect, useState } from "react";
import "./Banner.css";
// import "animate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Banner = () => {
  const [direccion, setDireccion] = useState("");
  const [direcciones, setDirecciones] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const direccionesGuardadas = JSON.parse(localStorage.getItem("direcciones")) || [];
    if (direccionesGuardadas.length > 0) {
      setDireccion(direccionesGuardadas[0]); // Establecer la última dirección como la actual
    }
    setDirecciones(direccionesGuardadas);
  }, []);

  const numeroWhatsApp = "+5492284515431";

  const actualizarDireccion = (event) => {
    const nuevaDireccion = event.target.value;
    setDireccion(nuevaDireccion);
  };

  const abrirWhatsApp = () => {
    const mensajeCompleto = `Hola, me podrás mandar un auto a ${direccion}?`;
    const mensajeCodificado = encodeURIComponent(mensajeCompleto);
    window.open(
      `https://wa.me/${numeroWhatsApp}?text=${mensajeCodificado}`,
      "_blank"
    );

    let direccionesGuardadas = JSON.parse(localStorage.getItem("direcciones")) || [];
    direccionesGuardadas = [direccion, ...direccionesGuardadas.filter(dir => dir !== direccion)].slice(0, 5);
    localStorage.setItem("direcciones", JSON.stringify(direccionesGuardadas));
    
    setDirecciones(direccionesGuardadas);
  };

  const seleccionarDireccion = (direccionSeleccionada) => {
    setDireccion(direccionSeleccionada);
    setShowDropdown(false); // Cerrar el dropdown al seleccionar una dirección
  };

  return (
    <div className="container-fluid banner">
      <div className="banner-container container">
        <h1>Pídelo aquí 👇</h1>
        <div className="mensaje">
          <div className="dropdown">
            <input
              type="text"
              placeholder="Ingresa la dirección"
              value={direccion}
              onChange={actualizarDireccion}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => setTimeout(() => setShowDropdown(false), 100)} // Delay to allow click event
            />
            {showDropdown && direcciones.length > 0 && (
              <ul className="dropdown-menu show text-center" style={{ width: '100%' }}>
                {direcciones.map((dir, index) => (
                  dir !== direccion && (
                    <li key={index} onMouseDown={() => seleccionarDireccion(dir)} className="dropdown-item">
                      {dir}
                    </li>
                  )
                ))}
              </ul>
            )}
          </div>
          <button
            className="btn btn-success fs-3 banner__btn"
            onClick={abrirWhatsApp}
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="icon__whatsapp"
            />{" "}
          </button>
        </div>

        <div className="texto-dinamico mt-4">
          <h2>Respuesta inmediata</h2>
          <h3>Todos los medios de pago</h3>
          <h3>Viajes larga distancia</h3>
          <h3>Encomiendas</h3>
        </div>
      </div>
    </div>
  );
};

export default Banner;
