import React from 'react';
import './WhatsappButton.css';
import whatsappIcon from './whatsapp.png';

const WhatsappButton = () => {
  const phoneNumber = '+5492284515431'; 

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={whatsappIcon} alt="WhatsApp" />
    </a>
  );
};

export default WhatsappButton;
