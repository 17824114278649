import "./Contacto.css";
import FormContacto from "../../componentes/FormContacto/FormContacto";
// import logoImage from '../../imagenes/coche.png';
const Contacto = () => {
  return (
    <div className="container contacto__container my-4">
      <div className="animated"></div>
      <div className="contacto_form">
        <FormContacto />
        {/* <div className="logo d-flex justify-center flex-column align-items-center">
        <h1 className="h1">Remis <br/>Aquí 👇</h1>
        <img src={logoImage} alt=""/>
        </div> */}
        
        
          
      </div>

      <div className="mensaje mt-4 bg-secondary p-4">
        <p className="text-center text-light">
          Si su consulta no fue respondida en menos de 48 hs, puede reenviarla
          por alguno de estos medios:
        </p>
        <h4>info@remis.com</h4>
      </div>
    </div>
  );
};

export default Contacto;
